// USA
export const locale = {
  TRANSLATOR: {
    SELECT: 'Dilinizi seçiniz',
  },
  MENU: {
    NEW: 'yeni',
    ACTIONS: 'Aksiyonlar',
    CREATE_POST: 'Yeni Gönderi Oluştur',
    PAGES: 'Sayfa',
    FEATURES: 'Özellikler',
    APPS: 'Uygulamalar',
    DASHBOARD: 'Dashboard',
  },
  AUTH: {
    GENERAL: {
      OR: 'Veya',
      SUBMIT_BUTTON: 'Gönder',
      CANCEL_BUTTON: 'İptal',
      NO_ACCOUNT: 'Hesabınız yok mu?',
      SIGNUP_BUTTON: 'Üye Ol',
      SIGNOUT_BUTTON: 'Çıkış Yap',
      FORGOT_BUTTON: 'Parolamı unuttum',
      BACK_BUTTON: 'Geri',
      PRIVACY: 'Gizlilik',
      LEGAL: 'Şartlar',
      CONDITIONS: 'Koşullar',
      CONTACT: 'İletişim',
      IAGREE: 'Kabul ediyorum',
      TERMS: 'şartlar ve koşulları',
    },
    LOGIN: {
      WELLCOME: 'Hoşgeldiniz!',
      TITLE: 'Hesabınıza Girin',
      NEW_HERE: 'Burada yeni misiniz?',
      BUTTON: 'Oturum aç',
    },
    FORGOT: {
      TITLE: 'Şifrenizi mi unuttunuz?',
      DESC: 'Şifrenizi sıfırlamak için e-posta adresinizi girin',
      SUCCESS: 'Hesabınız başarıyla sıfırlandı.',
    },
    REGISTER: {
      TITLE: 'Kaydolun',
      DESC: 'Hesabınızı oluşturmak için bilgilerinizi girin',
      SUCCESS: 'Hesabınız başarıyla kaydedildi.',
    },
    PROFILE: {
      MY_PROFILE: 'Profilim',
    },
    INPUT: {
      EMAIL: 'Eposta',
      FULLNAME: 'Ad Soyad',
      PASSWORD: 'Şifre',
      CONFIRM_PASSWORD: 'Şifreyi Onayla',
      USERNAME: 'Kullanıcı adı',
    },
    VALIDATION: {
      INVALID: '{{name}} geçerli değil',
      REQUIRED: '{{name}} gerekli',
      MIN_LENGTH: '{{name}} için minimum uzunluk {{min}}',
      AGREEMENT_REQUIRED:
                'Kabul edilen şartlar ve koşulları onaylamak gereklidir',
      NOT_FOUND: 'İstenen {{name}} bulunamadı',
      INVALID_LOGIN: 'Giriş detaylarınız yanlış',
      REQUIRED_FIELD: 'Gerekli alan',
      MIN_LENGTH_FIELD: 'Minimum alan uzunluğu:',
      MAX_LENGTH_FIELD: 'Maksimum alan uzunluğu:',
      INVALID_FIELD: 'Alan geçerli değil',
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: 'Seçilen kayıt sayısı: ',
      ALL: 'Hepsi',
      SUSPENDED: 'Askıda',
      ACTIVE: 'Aktif',
      FILTER: 'Filtre',
      BY_STATUS: 'Duruma göre',
      BY_TYPE: 'Türe göre',
      BUSINESS: 'Kurumsal',
      INDIVIDUAL: 'Bireysel',
      SEARCH: 'Arama',
      IN_ALL_FIELDS: 'tüm alanlarda',
    },
    ECOMMERCE: 'Liste',
    CUSTOMERS: {
      CUSTOMERS: 'Kayıtlar',
      CUSTOMERS_LIST: 'Kayıt listesi',
      NEW_CUSTOMER: 'Yeni Kayıt',
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: 'Kayıt Silme',
        DESCRIPTION:
                    'Bu kaydı kalıcı olarak silmek istediğinizden emin misiniz?',
        WAIT_DESCRIPTION: 'Kayıt siliniyor...',
        MESSAGE: 'Kayıt silindi',
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: 'Kayıtları Sil',
        DESCRIPTION:
                    'Bu kaytları kalıcı olarak silmek istediğinizden emin misiniz?',
        WAIT_DESCRIPTION: 'Kayıtlar siliniyor...',
        MESSAGE: 'Seçilmiş kayıtlar silindi',
      },
      UPDATE_STATUS: {
        TITLE: 'Seçilen kayıt için durum güncellendi',
        MESSAGE: 'Seçilen kayıtların durumu başarıyla güncellendi',
      },
      EDIT: {
        UPDATE_MESSAGE: 'Kayıt güncellendi',
        ADD_MESSAGE: 'Yeni kayıt oluşturuldu',
      },
    },
  },
};
